import mixpanel from 'mixpanel-browser'

import config from 'lib/config'
import logError from 'lib/logError'

export const initializeMixpanel = () => {
  config.MIXPANEL && mixpanel.init(config.MIXPANEL)

  config.MIXPANEL_SECONDARY &&
    mixpanel.init(config.MIXPANEL_SECONDARY, {}, 'mixpanel_secondary')
}

const debugMode = window?.localStorage?.debugMode === '1'

const trackEvent = (module, eventName, payload = {}, hideToast = false) => {
  if (config.IS_TEST_MODE) {
    return
  }
  payload = {
    ...window?.globals?._logData,
    ...payload,
  }
  debugMode &&
    window.console.log(
      'TRACKEVENT:',
      { module, eventName, payload },
      window?.globals?._logData
    )
  // if (config.ENV === 'development') return
  const showLogs = !hideToast && debugMode
  try {
    if (!Object.keys(trackEvent.modules).includes(module)) {
      showLogs &&
        window?.globals?.service?.toast(
          'TRACKEVENT: module not part of trackEvent.modules: ' + eventName,
          { type: 'error' }
        )
      return window.console.error(
        'TRACKEVENT: module not part of trackEvent.modules',
        eventName
      )
    }
    if (!Object.keys(trackEvent.events).includes(eventName)) {
      window.console.log(
        'Object.keys(trackEvent.events)',
        Object.keys(trackEvent.events),
        eventName
      )
      showLogs &&
        window?.globals?.service?.toast(
          'TRACKEVENT: eventName not part of trackEvent.events: ' + eventName,
          { type: 'error' }
        )
      return window.console.error(
        'TRACKEVENT: eventName not part of trackEvent.eventName',
        eventName
      )
    }

    if (module === trackEvent.modules.secondaryAccount) {
      config.MIXPANEL_SECONDARY &&
        mixpanel?.mixpanel_secondary?.track?.(eventName, payload)
    } else if (config.MIXPANEL) {
      mixpanel?.track(eventName, payload)
    }
    showLogs && window?.globals?.service?.toast(eventName)
  } catch (error) {
    logError(error, 'trackEvent', {
      eventName,
      module,
    })
  }
}

export const trackPH = (module, eventName, payload) => {
  if (config.IS_TEST_MODE) {
    return
  }
  try {
    debugMode &&
      window.console.log('TRACKPH:', eventName, {
        module,
        payload: {
          ...window?.globals?._logData,
          ...payload,
        },
      })
  } catch (e) {
    logError(e, 'trackPH', {
      eventName,
      module,
    })
  }
}

trackEvent.modules = {
  auth: 'auth',
  chat: 'chat',
  dock: 'dock',
  header: 'header',
  queue: 'queue',
  quickfind: 'quickfind',
  service: 'service',
  socket: 'socket',
  fetcher: 'fetcher',
  redux: 'redux',
  secondaryAccount: 'secondaryAccount',
  settings: 'settings',
  ctwa: 'ctwa',
  createOrg: 'createOrg',
  integrations: 'integrations',
  linkCreator: 'linkCreator',
  enigmaSales: 'enigmaSales',
  apps: 'apps',
  onBoardingFlow: 'onBoardingFlow',
  guru: 'guru',
  catalogManager: 'catalogManager',
  billing: 'billing',
  flowLibrary: 'flowLibrary',
  flowCanvas: 'flowCanvas',
}

trackPH.modules = trackEvent.modules

/**
 * component_action
 * e.g: conversation_click
 **/
trackEvent.events = {
  conversation_filter_change: 'conversation_filter_change',
  conversation_filter_clear: 'conversation_filter_clear',
  conversation_select: 'conversation_select',
  conversation_search_filter_click: 'conversation_search_filter_click',
  conversation_search: 'conversation_search',
  conversation_search_clear: 'conversation_search_clear',
  conversation_manage_chat: 'conversation_manage_chat',
  view_as_change: 'view_as_change',
  conversation_search_result_select: 'conversation_search_result_select',
  chatwindow_header_name_click: 'chatwindow_header_name_click',
  chatwindow_header_tag_click: 'chatwindow_header_tag_click',
  chatwindow_assign_to_change: 'chatwindow_assign_to_change',
  chatwindow_options_click: 'chatwindow_options_click',
  chatwindow_mobile_back_click: 'chatwindow_mobile_back_click',
  chatreply_quick_option_click: 'chatreply_quick_option_click',
  chatreply_quick_option_close: 'chatreply_quick_option_close',
  chatreply_quick_option_change: 'chatreply_quick_option_change',
  chatreply_quick_template_select: 'chatreply_quick_template_select',
  chatreply_quick_reply_select: 'chatreply_quick_reply_select',
  chatreply_expiry_template_select: 'chatreply_expiry_template_select',
  chatreply_private_note_click: 'chatreply_private_note_click',
  chatreply_file_upload: 'chatreply_file_upload',
  chatreply_file_upload_complete: 'chatreply_file_upload_complete',
  chatreply_attachment_delete: 'chatreply_attachment_delete',
  chatreply_emoji: 'chatreply_emoji',
  chatreply_emoji_toggle: 'chatreply_emoji_toggle',
  chatreply_text_focus: 'chatreply_text_focus',
  chatreply_send_message: 'chatreply_send_message',
  chatreply_send_blocked: 'chatreply_send_blocked',
  chatreply_audio_upload_audio: 'chatreply_audio_upload_audio',
  chatreply_audio_action: 'chatreply_audio_action',
  template_edit_post_click: 'template_edit_post_click',
  template_edit_cancel_click: 'template_edit_cancel_click',
  template_modal_close: 'template_modal_close',
  template_advanced_option_toggle: 'template_advanced_option_toggle',
  conversation_list_refresh_toast: 'conversation_list_refresh_toast',
  enigma_take_over_by_agent: 'enigma_take_over_by_agent',
  mobile_app_link_click: 'mobile_app_link_click',

  // DOCK:START
  dock_click_dock_tab: 'dock_click_dock_tab',
  dock_customer_profile_add_address: 'dock_customer_profile_add_address',
  dock_customer_profile_add_new_tag: 'dock_customer_profile_add_new_tag',
  dock_customer_profile_edit_name: 'dock_customer_profile_edit_name',
  dock_customer_profile_edit_name_cancel:
    'dock_customer_profile_edit_name_cancel',
  dock_customer_profile_edit_name_save: 'dock_customer_profile_edit_name_save',
  dock_customer_profile_sections_click: 'dock_customer_profile_sections_click',
  dock_customer_profile_media_tabs_click:
    'dock_customer_profile_media_tabs_click',
  dock_customer_profile_media_click: 'dock_customer_profile_media_click',
  dock_tags_sections_click: 'dock_tags_sections_click',
  dock_tags_add_new_tag: 'dock_tags_add_new_tag',
  dock_tags_delete_tag: 'dock_tags_delete_tag',
  dock_tags_add_new_tag_tag_click: 'dock_tags_add_new_tag_tag_click',
  dock_tags_add_new_tag_button_click: 'dock_tags_add_new_tag_button_click',
  dock_orders_search: 'dock_orders_search',
  dock_orders_tab_change: 'dock_orders_tab_change',
  dock_orders_card_toggle: 'dock_orders_card_toggle',
  dock_orders_action_click: 'dock_orders_action_click',
  dock_products_cart_open: 'dock_products_cart_open',
  dock_products_cart_close: 'dock_products_cart_close',
  dock_products_cart_update: 'dock_products_cart_update',
  dock_products_cart_send_to_chat_click:
    'dock_products_cart_send_to_chat_click',
  dock_products_cart_checkout_click: 'dock_products_cart_checkout_click',
  dock_products_cart_checkout_close: 'dock_products_cart_checkout_close',
  dock_products_cart_checkout_order_summary_toggle:
    'dock_products_cart_checkout_order_summary_toggle',
  dock_products_cart_checkout_send_to_chat_click:
    'dock_products_cart_checkout_send_to_chat_click',
  dock_products_cart_checkout_process_order_click:
    'dock_products_cart_checkout_process_order_click',
  dock_products_cart_checkout_add_address_click:
    'dock_products_cart_checkout_add_address_click',
  dock_products_cart_checkout_select_address:
    'dock_products_cart_checkout_select_address',
  dock_products_cart_checkout_change_address:
    'dock_products_cart_checkout_change_address',
  dock_products_list_search: 'dock_products_list_search',
  dock_products_list_filter_toggle: 'dock_products_list_filter_toggle',
  dock_products_list_filter_update: 'dock_products_list_filter_update',
  dock_products_list_product_info_click:
    'dock_products_list_product_info_click',
  dock_products_list_add_to_cart_click: 'dock_products_list_add_to_cart_click',
  dock_products_list_send_to_chat_click:
    'dock_products_list_send_to_chat_click',
  dock_products_product_info_send_to_chat_click:
    'dock_products_product_info_send_to_chat_click',
  dock_products_product_info_add_to_cart_click:
    'dock_products_product_info_add_to_cart_click',
  dock_products_product_info_filter_update:
    'dock_products_product_info_filter_update',
  dock_products_product_info_gallery_send_to_chat:
    'dock_products_product_info_gallery_send_to_chat',
  dock_catalog_expand_click: 'dock_catalog_expand_click',
  dock_bottom_expand_click: 'dock_bottom_expand_click',
  // DOCK:END

  storelist_store_switch: 'storelist_store_switch',
  storecontent_link_click: 'storecontent_link_click',
  maintabs_tab_change: 'maintabs_tab_change',

  agentteamlist_search: 'agentteamlist_search',
  agentteamlist_tab_change: 'agentteamlist_tab_change',
  agentteamlist_assign_change: 'agentteamlist_assign_change',
  queuelist_select_conversation: 'queuelist_select_conversation',
  queuelist_close_chat: 'queuelist_close_chat',
  chatpreview_goto_chat: 'chatpreview_goto_chat',
  queuelist_toggle_chatpreview: 'queuelist_toggle_chatpreview',
  chatwindowassign_previewmode_change: 'chatwindowassign_previewmode_change',
  queue_refresh_list_toast: 'queue_refresh_list_toast',

  api_call: 'api_call',
  redux_action: 'redux_action',
  header_store_info_click: 'header_store_info_click',
  header_mobile_menu_click: 'header_mobile_menu_click',

  internet_offline_toast: 'internet_offline_toast',
  sleep_toast: 'sleep_toast',

  // settings
  shopify_merger: 'shopify_merger',
  shopify_merger_mismatch: 'shopify_merger_mismatch',
  // service
  app_crash: 'app_crash',
  shown_health_banner: 'shown_health_banner',

  // annoucement

  show_woo_kill_alert_2023: 'show_woo_kill_alert_2023',
  stop_woo_kill_alert_2023: 'stop_woo_kill_alert_2023',
  accept_woo_kill_alert_2023: 'accept_woo_kill_alert_2023',

  show_gpt_pay_alert_2023: 'show_gpt_pay_alert_2023',
  stop_gpt_pay_alert_2023: 'stop_gpt_pay_alert_2023',
  accept_gpt_pay_alert_2023: 'accept_gpt_pay_alert_2023',

  show_catalog_alert_2023: 'show_catalog_alert_2023',
  stop_catalog_alert_2023: 'stop_catalog_alert_2023',
  accept_catalog_alert_2023: 'accept_catalog_alert_2023',

  show_pricing_alert_2023: 'show_pricing_alert_2023',
  stop_subscription_2023: 'stop_subscription_2023',
  accept_subscription_2023: 'accept_subscription_2023',

  enable_cod_button: 'enable_cod_button',
  ignore_cod_button: 'ignore_cod_button',
  show_cod_news: 'show_cod_news',

  enable_seg_button: 'enable_seg_button',
  ignore_seg_button: 'ignore_seg_button',
  show_seg_news: 'show_seg_news',

  enable_blling_alert_button: 'enable_blling_alert_button',
  ignore_blling_alert_button: 'ignore_blling_alert_button',
  show_blling_alert_news: 'show_blling_alert_news',

  show_sync_news: 'show_sync_news',
  ignore_sync_button: 'ignore_sync_button',

  enable_broadcast_button: 'enable_broadcast_button',
  show_broadcast_news: 'show_broadcast_news',
  ignore_broadcast_button: 'ignore_broadcast_button',
  learn_more_broadcast_button: 'learn_more_broadcast_button',

  down_time_sept_show: 'down_time_sept_show',
  down_time_sept_accept: 'down_time_sept_accept',
  down_time_sept_ignore: 'down_time_sept_ignore',

  show_wismo_launch: 'show_wismo_launch',
  accept_wismo_launch: 'accept_wismo_launch',
  stop_wismo_launch: 'stop_wismo_launch',

  show_broadcast_banner: 'show_broadcast_banner',
  broadcast_learn_more_click: 'broadcast_learn_more_click',
  broadcast_not_interested_click: 'broadcast_not_interested_click',

  pricing_2024_show_banner: 'pricing_2024_show_banner',
  pricing_2024_learn_more: 'pricing_2024_learn_more',
  pricing_2024_not_interested_click: 'pricing_2024_not_interested_click',

  catalog_2024_july_show_banner: 'catalog_2024_july_show_banner',
  catalog_2024_july_learn_more: 'catalog_2024_july_learn_more',
  catalog_2024_july_not_interested_click:
    'catalog_2024_july_not_interested_click',

  broadcast_2024_oct_show_banner: 'broadcast_2024_oct_show_banner',
  broadcast_2024_oct_learn_more: 'broadcast_2024_oct_learn_more',
  broadcast_2024_oct_not_interested_click:
    'broadcast_2024_oct_not_interested_click',

  // login - secondaryAccount events
  log_in: 'log_in',
  log_out: 'log_out',

  // checkout - scondaryAccount events
  checkout_sent: 'checkout_sent',

  //ctwa
  ctwa_list_open: 'ctwa_list_open',
  ctwa_overview_open: 'ctwa_overview_open',
  ctwa_overview_datatab_open: 'ctwa_overview_datatab_open',
  ctwa_overview_export_click: 'ctwa_overview_export_click',

  //
  chatpowers_link_click: 'chatpowers_link_click',

  // createOrg
  createOrg_submit_click: 'createOrg_submit_click',

  //onboarding qr
  onboarding_qr_shown: 'onboarding_qr_shown',
  onboarding_qr_scanned: 'onboarding_qr_scanned',
  onboarding_qr_closed_manually: 'onboarding_qr_closed_manually',
  header_create_new_org_click: 'header_create_new_org_click',

  integrations_modal_connect_click: 'integrations_modal_connect_click',

  // link creator
  video_transcoding_started: 'video_transcoding_started',
  video_transcoding_finished: 'video_transcoding_finished',
  video_transcoding_failed: 'video_transcoding_failed',

  // whatsapp_pay
  whatsapp_pay_connect: 'whatsapp_pay_connect',
  whatsapp_pay_disconnect: 'whatsapp_pay_disconnect',
  whatsapp_pay_cancel: 'whatsapp_pay_cancel',

  // chat
  chat_send_message_failure: 'chat_send_message_failure',

  // enigmaSales
  shipping_duplicate_name_found: 'shipping_duplicate_name_found',
  payment_duplicate_name_found: 'payment_duplicate_name_found',

  // whatsapp_connect
  whatsapp_connect_success_page_call: 'whatsapp_connect_success_page_call',
  whatsapp_connect_callback_received: 'whatsapp_connect_callback_received',
  whatsapp_connect_api_call: 'whatsapp_connect_api_call',

  //billing plan change
  update_subscription_plan: 'update_subscription_plan',
  billing_upgrade_click: 'billing_upgrade_click',
  billing_lite_upgrade_intent: 'billing_lite_upgrade_intent',
  billing_lite_upgrade_cancel: 'billing_lite_upgrade_cancel',
  billing_stripe_checkout_initiated: 'billing_stripe_checkout_initiated',

  //connect new catalog
  promote_catalog_shown: 'promote_catalog_shown',
  promote_catalog_close: 'promote_catalog_close',
  promote_catalog_click: 'promote_catalog_click',

  //apps
  app_click: 'app_click',

  //hallo bot language selection.
  bot_foreign_language_selection: 'bot_foreign_language_selection',
  app_search_focus: 'app_search_focus',
  app_card_click: 'app_card_click',

  //sign up flow
  onboarding_signup_cta: 'onboarding_signup_cta',
  onboarding_join_cta: 'onboarding_join_cta',
  onboarding_create_account_cta: 'onboarding_create_account_cta',
  onboarding_connect_whatsapp: 'onboarding_connect_whatsapp',
  onboarding_connect_success: 'onboarding_connect_success',

  // guru suggestion
  guru_suggestion_click: 'guru_suggestion_click',
  guru_suggestion_send_click: 'guru_suggestion_send_click',
  guru_suggestion_edit_click: 'guru_suggestion_edit_click',
  guru_suggestion_close_click: 'guru_suggestion_close_click',
  track_reditus: 'track_reditus',

  //broadcast_testing
  create_new_broadcast: 'create_new_broadcast',

  //store_switcher
  store_switch_no_access: 'store_switch_no_access',

  // catalogManager
  catalog_app_banner_click: 'catalog_app_banner_click',
  catalog_app_publish_check: 'catalog_app_publish_check',
  catalog_app_save_button_click: 'catalog_app_save_button_click',
  catalog_app_save_next_click: 'catalog_app_save_next_click',

  //dock catalog add to cart
  catalog_on_add_to_cart_click: 'catalog_on_add_to_cart_click',
  catalog_on_add_to_cart_button_click: 'catalog_on_add_to_cart_button_click',
  catalog_on_add_to_cart_api_call_start:
    'catalog_on_add_to_cart_api_call_start',
  catalog_on_add_to_cart_api_call_end: 'catalog_on_add_to_cart_api_call_end',
  catalog_on_cart_item_edit: 'catalog_on_cart_item_edit',
  catalog_on_clear_cart: 'catalog_on_clear_cart',

  //lite plan
  facebook_billing_setup_click: 'facebook_billing_setup_click',

  //spam template
  spam_template_create: 'spam_template_create',

  // quick_find
  quick_find_tab_change: 'quick_find_tab_change',
  quick_find_filter_change: 'quick_find_filter_change',
  quick_find_clear_click: 'quick_find_clear_click',
  quick_find_search: 'quick_find_search',

  //billing
  billing_phone_pe_checkout_cancelled: 'billing_phone_pe_checkout_cancelled',
  billing_phone_pe_checkout_concluded: 'billing_phone_pe_checkout_concluded',
  billing_phone_pe_checkout_initiated: 'billing_phone_pe_checkout_initiated',

  //flowhippo
  flowhippo_billing_plan_card_click: 'flowhippo_billing_plan_card_click',
  flowhippo_billing_phone_pe_pending_timeout:
    'flowhippo_billing_phone_pe_pending_timeout',
  flowhippo_billing_payment_received: 'flowhippo_billing_payment_received',
  // header
  header_profile_click: 'header_profile_click',
  header_switch_org_click: 'header_switch_org_click',
  header_org_settings_link_click: 'header_org_settings_link_click',
  header_personal_link_click: 'header_personal_link_click',
  header_sign_out_click: 'header_sign_out_click',
  header_logo_click: 'header_logo_click',
  header_tab_change: 'header_tab_change',

  // flowLibrary
  flowLibrary_create_new_flow_click: 'flowLibrary_create_new_flow_click',
  flowLibrary_tab_change: 'flowLibrary_tab_change',
  flowLibrary_card_click: 'flowLibrary_card_click',
  flowLibrary_flow_edit_tab: 'flowLibrary_flow_edit_tab',
  flowLibrary_flow_snapshot_click: 'flowLibrary_flow_snapshot_click',
  flowLibrary_header_publish_click: 'flowLibrary_header_publish_click',
  flowLibrary_header_delete_click: 'flowLibrary_header_delete_click',
  flowLibrary_header_use_playbook_click:
    'flowLibrary_header_use_playbook_click',
  flowLibrary_header_close_click: 'flowLibrary_header_close_click',
  flowLibrary_header_back_click: 'flowLibrary_header_back_click',
  flowLibrary_free_to_paid_plan_nudge: 'flowLibrary_free_to_paid_plan_nudge',

  // flowCanvas
  flowCanvas_header_publish_click: 'flowCanvas_header_publish_click',
  flowCanvas_header_close_click: 'flowCanvas_header_close_click',
  flowCanvas_header_back_click: 'flowCanvas_header_back_click',
  flowCanvas_header_use_playbook_click: 'flowCanvas_header_use_playbook_click',
  flowCanvas_header_delete_click: 'flowCanvas_header_delete_click',
  flowCanvas_footer_save_click: 'flowCanvas_footer_save_click',
  flowCanvas_footer_close_click: 'flowCanvas_footer_close_click',
  flowCanvas_free_to_paid_plan_nudge: 'flowCanvas_free_to_paid_plan_nudge',
  flowCanvas_publish_no_credits: 'flowCanvas_publish_no_credits',

  // integrations
  integrations_card_connect_click: 'integrations_card_connect_click',
  integrations_card_delete_click: 'integrations_card_delete_click',
  integrations_card_edit_click: 'integrations_card_edit_click',
  flows_integrations_modal_connect_click:
    'flows_integrations_modal_connect_click',

  // usageLogs

  usage_logs_export_confirm_click: 'usage_logs_export_confirm_click',

  // billing
  billing_phone_pe_pending_timeout: 'billing_phone_pe_pending_timeout',
  billing_pay_now_click: 'billing_pay_now_click',
  billing_payment_callback: 'billing_payment_callback',
  billing_payment_received: 'billing_payment_received',
  billing_plan_card_click: 'billing_plan_card_click',

  flows_billing_phone_pe_checkout_initiated:
    'flows_billing_phone_pe_checkout_initiated',
  flows_billing_phone_pe_checkout_concluded:
    'flows_billing_phone_pe_checkout_concluded',
  flows_billing_phone_pe_checkout_cancelled:
    'flows_billing_phone_pe_checkout_cancelled',
  //signup lead
  signup_lead: 'signup_lead',

  //2fa
  two_fa_setup_success: 'two_fa_setup_success',
  mfa_login_request_otp: 'mfa_login_request_otp',
  mfa_enroll_request_otp: 'mfa_enroll_request_otp',
  mfa_enroll_error: 'mfa_enroll_error',
  mfa_login_error: 'mfa_login_error',

  //shopify welcome prompt
  shopify_welcome_prompt_whatsapp_click:
    'shopify_welcome_prompt_whatsapp_click',
  already_have_account_click: 'already_have_account_click',
  show_shopify_welcome_prompt: 'show_shopify_welcome_prompt',
  shopify_welcome_prompt_email_click: 'shopify_welcome_prompt_email_click',
}
trackPH.events = trackEvent.events

export default trackEvent
